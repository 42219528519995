<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>日语课程</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
          <el-button
            type="success"
            @click="
              dialogVisible = true;
              form = { isEnable: true };
              cateList = [];
            "
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>日语课程</span>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column prop="title" label="标题"></el-table-column>
          <!-- <el-table-column label="代表图地址">
            <template slot-scope="scope" width="150px">
              <img :src="domain + scope.row.imgUrl" :style="{ height: '50px' }" alt />
            </template>
          </el-table-column> -->
          <el-table-column prop="classCount" label="课时"></el-table-column>
          <el-table-column label="课程类型" prop="classType"></el-table-column>
          <el-table-column label="语言基础" prop="baseList"></el-table-column>
          <el-table-column prop="classTime" label="上课时间"></el-table-column>
          <el-table-column label="学习目的" prop="lableList"></el-table-column>
          <!-- <el-table-column prop="startAmount" label="价格区间"></el-table-column> -->
          <el-table-column prop="viewCount" label="浏览次数"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="createTime" label="添加时间"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    @click="delClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="目录管理" placement="top">
                  <el-link
                    :href="
                      './catalogue?id=' +
                      scope.row.id +
                      '&title=' +
                      scope.row.title
                    "
                    target="_blank"
                  >
                    <el-button
                      type="warning"
                      size="small"
                      icon="el-icon-thumb"
                    ></el-button>
                  </el-link>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 -->
    <el-dialog
      :title="form.id > 0 ? '编辑日语课程' : '添加日语课程'"
      :visible.sync="dialogVisible"
      @opened="dialogOpened()"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-tabs type="card">
          <el-tab-pane label="基本信息">
            <el-form-item
              label="标题"
              prop="title"
              :rules="[
                { required: true, message: '请输入标题' },
                { min: 2, max: 30, message: '标题最少2字，最大30字' },
              ]"
            >
              <el-input
                v-model="form.title"
                placeholder="请输入标题"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="图片"
              :rules="[{ required: true, message: '不能为空' }]"
            >
              <div id="uploadBtn1" class="uploader uploader-warning"></div>
              <img
                id="relativeName"
                :src="domain + form.imgUrl"
                style="height: 40px; position: relative; left: 10px; top: 20px"
                v-if="form.imgUrl"
              />
              <div class="uploader-text">* 图片尺寸：推荐454px*340px</div>
            </el-form-item>

            <el-form-item label="视频地址">
              <div id="uploadBtn2" class="uploader uploader-warning"></div>
              <span id="relativeName" style="margin-left: 20px">{{
                form.videoUrl
              }}</span>
                        <span
                class="imgtext"
                title
                type="info"
                style="position: relative;  left: 0px;  top: 1px"
                >*视频格式：mp4；视频最大不超过1G；</span
              >
            </el-form-item>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="课时"
                  prop="classCount"
                  :rules="[{ required: true, message: '请输入课时' }]"
                >
                  <el-input
                    v-model="form.classCount"
                    placeholder="请输入课时"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="上课时间"
                  prop="classTime"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                  <el-input
                    v-model="form.classTime"
                    placeholder="请输入上课时间"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="课程类型" prop="classType">
              <el-select
                v-model="form.classType"
                placeholder="请选择类型"
                clearable
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in classlist"
                  :label="item.name"
                  :value="item.name"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="学习目的"
              prop="lableList"
              :rules="{ required: true, message: '不能为空' }"
            >
              <el-select
                v-model="form.lableList"
                placeholder="请选择学习目的"
                clearable
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in labelList"
                  :label="item.label"
                  :value="item.label"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="语言基础"
              prop="baseList"
              :rules="{ required: true, message: '不能为空' }"
            >
              <el-select
                v-model="form.baseList"
                placeholder="请选择语言基础"
                clearable
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in baseList"
                  :label="item.name"
                  :value="item.name"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="开始价格"
                  prop="startAmount"
                  :rules="[{ required: true, message: '请输入价格' }]"
                >
                  <el-input v-model="form.startAmount" placeholder="请输入价格">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结束价格" prop="endAmount">
                  <el-input v-model="form.endAmount" placeholder="请输入价格">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="排序字段" prop="sort">
              <el-input
                v-model.number="form.sort"
                placeholder="请输入排序字段"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="课程介绍">
            <el-form-item label="课程介绍" prop="content">
              <div style="position: relative">
                <vue-ueditor-wrap
                  v-model="form.content"
                  :config="editorConfig"
                ></vue-ueditor-wrap>
              </div>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: { VueUeditorWrap },
  data() {
    return {
      editorConfig: {
        initialFrameHeight: 500, //设置高度
        initialFrameWidth: "100%", //设置宽度
        UEDITOR_HOME_URL: url.getUEConfig(),
        serverUrl: url.getUE(),
      },
      domain: url.getDomain(),
      key: "",
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      labelList: [],
      classlist: [],
      baseList: [],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    // 查询列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getVideo("GetList_Japanese");

      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //获取课程类型列表
    class: function () {
      var _this = this;
      var link = url.getVideo("GetJapaneseType");
      $.get(link, {}, (data) => {
        _this.classlist = data;
      });
    },
    //获取学习目的列表
    label: function () {
      var _this = this;
      var modelid = 4;
      var link = url.getSys_BasicData("GetBaseTree");
      $.get(link, { modelid: modelid }, (data) => {
        _this.labelList = data;
      });
    },
    // // 获取日语基础列表
    base: function () {
      var _this = this;
      var link = url.getVideo("GetParam_Japanese");

      $.get(link, {}, (data) => {
        _this.baseList = data.baseList;
      });
    },
    //搜索查询
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    //上传文件
    dialogOpened: function () {
      var _this = this;

      // 上传视频
      if ($("#uploadBtn2 input").length == 0) {
        $("#uploadBtn2").uploader({
          text: "上传视频",
          type:'single',
          fileExts: "mp4",
          uploadType:3,
          debug: true,
          maxSize: 1024 * 1024 * 500, 
          onSuccess: function (data) {
            var src = url.getDomain() + data.absoluteName;
            _this.$set(_this.form, "videoUrl", data.absoluteName);
          },
        });
      }

      // 上传图片
      if ($("#uploadBtn1 input").length == 0) {
        $("#uploadBtn1").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          type: "cutdouble",
          minWidth: 454,
          minHeight: 340,
          maxWidth: 960,
          maxHeight: 640,
          more: false,
          auto: true,
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var small = data.small;
            var big = data.big;
            //增加上传处理后台处理
            var data = {
              imgSmall: small.relativeName,
              imgBig: big.relativeName,
            };
            var src = url.getDomain() + data.imgSmall;
            _this.$set(_this.form, "imgUrl", data.imgSmall);
          },
        });
        // $("#uploadBtn1").uploader({
        //   url: url.getUpload(),
        //   text: "上传文件",
        //   fileExts: "jpg;png",
        //   handleType: "0",
        //   maxSize: 1024 * 1024 * 1,
        //   onSuccess: function (data) {
        //     var src = url.getDomain() + data.relativeName;
        //     _this.$set(_this.form, "imgUrl", data.relativeName);
        //   },
        // });
      }
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getVideo("Save_Japanese");
      var info = this.form;
      info.classType = info.classType.toString();
      info.lableList = info.lableList.toString();
      info.baseList = info.baseList.toString();
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function (row) {
      var _this = this;
      var id = row.id;
      var link = url.getVideo("get_japanese");
      $.get(link, { id: id }, (res) => {
        if (res.classType) {
          res.classType = res.classType.split(",");
        }
        if (res.lableList) {
          res.lableList = res.lableList.split(",");
        }
        if (res.baseList) {
          res.baseList = res.baseList.split(",");
        }
        _this.dialogVisible = true;
        _this.form = res;
      });
    },
    //删除
    delClick: function (row) {
      var _this = this;
      var link = url.getVideo("Delete_Japanese"); //获取请求地址
      var id = row.id; //获取对象id

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    this.class();
    this.label();
    this.base();
    this.getList();
    //获取服务器列表
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
